<template>
  <div :class="$style.banner">
    {{ $t('layout.sandboxBanner.title') }}
  </div>
</template>

<script>
export default {
  name: 'LayoutDefaultSandboxBanner',
};
</script>

<style lang="scss" module>
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  background-color: #ffe000;
  transition: all ease var(--animate-duration);
}
</style>
