<template>
  <div :class="$style.container">
    <h3 :class="$style.title">
      {{ $t('subscription.blockedFunctionality.batchDownload.title') }}
    </h3>
    <p :class="$style.text">
      {{ $t('subscription.blockedFunctionality.batchDownload.firstText') }}
    </p>
    <p :class="$style.text">
      {{ $t('subscription.blockedFunctionality.batchDownload.lastText') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BatchDownloadFunctionality',
};
</script>

<style lang="scss" module>
.container {
  margin-top: 30px;

  .title {
    margin-bottom: var(--space-large);
  }

  .text {
    margin-bottom: 25px;
  }
}
</style>
