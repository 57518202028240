<template>
  <XModal
    close-button
    :width="step === 'initial' ? '792' : '544'"
    :is-open="value"
    :header-classes="[headerClasses]"
    :modal-classes="[$style.modal, modalClasses]"
    :actions-classes="[$style[actionsClasses]]"
    data-testid="limitExceddedInTrialModal"
    @close="handleClose"
  >
    <template #header>
      <p data-testid="headerTitle">
        {{ headerTitle }}
      </p>
    </template>

    <div
      v-show="step === 'initial'"
      :class="$style.content"
    >
      <div :class="$style.emptyDocumentBalance">
        <div :class="$style.titleContainer">
          <div :class="$style.mainHeading">
            <XIcon
              size="2x"
              icon="file-check"
            />
            <h2 :class="$style.title">
              {{ modalTitle }}
            </h2>
          </div>
        </div>
        <div
          :class="$style.messageContainer"
          data-testid="initialStepMessage"
        >
          <p data-testid="firstParagraph">
            {{ modalMessage.firstParagraph }}
          </p>
          <p data-testid="secondParagraph">
            {{ modalMessage.secondParagraph }}
          </p>
        </div>
      </div>
      <img
        :class="$style.image"
        :src="modalImage.src"
        :alt="modalImage.alt"
      />
    </div>

    <div v-show="step === 'form'">
      <div :class="$style.content">
        <div :class="$style.emptyDocumentBalance">
          <div :class="$style.titleContainer">
            <div :class="$style.mainHeading">
              <XIcon
                size="2x"
                icon="file-arrow-up"
              />
              <h2 :class="$style.title">
                {{ modalTitle }}
              </h2>
            </div>
            <span :class="$style.subtitle">
              {{ $t('accountModule.limitExceddedInTrialModal.subtitle.formToRequest') }}
            </span>
          </div>
        </div>
      </div>

      <div>
        <fieldset
          :class="$style.form"
          data-testid="formStep"
        >
          <XTextInput
            v-model="userData.name"
            :class="$style.field"
            :label="$t('accountModule.limitExceddedInTrialModal.formLabels.name')"
            :validations="v$.name"
            full-width
            required
            autofocus
          />
          <XTextInput
            v-model="userData.email"
            :class="$style.field"
            :label="$t('accountModule.limitExceddedInTrialModal.formLabels.email')"
            :validations="v$.email"
            full-width
            required
          />
          <XTextInput
            v-model="userData.accountName"
            :class="$style.field"
            :label="$t('accountModule.limitExceddedInTrialModal.formLabels.accountName')"
            :validations="v$.accountName"
            full-width
            required
          />
          <XTextInput
            v-model="userData.phoneNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :class="$style.field"
            :label="$t('accountModule.limitExceddedInTrialModal.formLabels.phoneNumber')"
            :placeholder="$t('acceptanceTerm.create.senderInfo.placeholder.senderPhone')"
            :validations="v$.phoneNumber"
            full-width
            required
          />
        </fieldset>
      </div>
    </div>

    <div
      v-show="step === 'success'"
      :class="$style.success"
    >
      <img
        :class="$style.icon"
        src="@flow/assets/icons/send.svg"
        height="64"
        width="64"
      />
      <p :class="$style.text">
        {{ $t('disabledFlow.purchase.success') }}
      </p>
    </div>
    <template #actions>
      <div :class="$style.buttonsContainer">
        <XButton
          v-bind="firstButtonStyle"
          data-testid="firstButton"
          @click="buttonActions.firstButton"
        >
          {{ buttonText.firstButton }}
        </XButton>
        <XButton
          v-show="step !== 'success'"
          v-bind="secondButtonStyle"
          data-testid="secondButton"
          @click="buttonActions.secondButton"
        >
          {{ buttonText.secondButton }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<script>
import { defineComponent, ref, reactive, computed, getCurrentInstance, watch, toRefs } from 'vue';
import useVuelidate from '@vuelidate/core';

import { useStore } from '@/store';
import { useI18n } from '@/locales';
import { required, email, phone } from '@base/validators';
import { useValidateAccountActions } from '@base/hooks';

import personRequesDocumentsImage from '@account/assets/images/premium/person-request-documents-image.png';
import personWhatsappAcceptanceTrialLimitImage from '@account/assets/images/premium/person-whatsapp-acceptance-trial-limit-image.png';

export default defineComponent({
  name: 'LimitExceddedInTrialModal',
  props: {
    plansLink: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    account: {
      type: Object,
      default: () => ({}),
    },
    submitedData: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const step = ref('initial');
    const userData = reactive({
      name: props.user?.name || '',
      email: props.user?.email || '',
      accountName: props.account?.name || '',
      phoneNumber: '',
    });
    const rules = {
      name: { required },
      email: { required, email },
      accountName: { required },
      phoneNumber: { required, phone },
    };

    const { modalData } = useValidateAccountActions();

    const { currentModalStep } = toRefs(modalData);

    const v$ = useVuelidate(rules, userData);
    const canSubmit = computed(() => !v$?.value?.$invalid);

    const store = useStore();
    const links = store.getters['info/getLinks'];

    const { t } = useI18n();

    const changeStep = (payload) => {
      step.value = payload;
    };

    watch(
      () => props.submitedData,
      (submitedData) => {
        if (submitedData) {
          changeStep('success');
        }
      }
    );
    watch(
      () => currentModalStep,
      (newInitialStep) => changeStep(newInitialStep)
    );

    const headerClasses = computed(() => step.value !== 'success' && proxy.$style.header);

    const modalClasses = computed(() => props.submitedData && proxy.$style.submited);

    const actionsClasses = computed(() => {
      const stepOptions = {
        initial: 'actionsInitial',
        form: 'actionsFormContainer',
        success: 'actionsSuccess',
      };

      return stepOptions[step.value];
    });

    const headerTitle = computed(() => {
      const locales = {
        initial: {
          createDocument: t('accountModule.limitExceddedInTrialModal.header.reachLimit'),
          createWhatsappAcceptance: t('acceptanceTerm.requestTrialExtension.header'),
        },
        form: t('accountModule.limitExceddedInTrialModal.header.formToRequest'),
        success: t('accountModule.limitExceddedInTrialModal.header.success'),
      };

      return step.value === 'initial' ? locales[step.value][props.context] : locales[step.value];
    });

    const modalTitle = computed(() => {
      const locales = {
        initial: {
          createDocument: t(
            'accountModule.limitExceddedInTrialModal.title.createDocumentReachLimit'
          ),
          createWhatsappAcceptance: t(
            'accountModule.limitExceddedInTrialModal.title.whatappAcceptanceReachLimit'
          ),
        },
        form: t('accountModule.limitExceddedInTrialModal.title.formToRequest'),
      };

      return locales[step.value]?.[props.context] || locales[step.value];
    });

    const modalMessage = computed(() => {
      const locales = {
        createDocument: {
          firstParagraph: t(
            'accountModule.limitExceddedInTrialModal.message.reachLimit.firstParagraph',
            { limit: props.account.maxDraftPacksInTrial }
          ),
          secondParagraph: t(
            'accountModule.limitExceddedInTrialModal.message.reachLimit.secondParagraph'
          ),
        },
        createWhatsappAcceptance: {
          firstParagraph: t(
            'acceptanceTerm.requestTrialExtension.message.reachLimit.firstParagraph'
          ),
          secondParagraph: t(
            'acceptanceTerm.requestTrialExtension.message.reachLimit.secondParagraph'
          ),
        },
      };

      return locales[props.context || 'createDocument'];
    });

    const modalImage = computed(() => {
      const contexts = {
        createDocument: {
          src: personRequesDocumentsImage,
          alt: 'Homem mexendo no computador',
        },
        createWhatsappAcceptance: {
          src: personWhatsappAcceptanceTrialLimitImage,
          alt: 'Mulher mexendo no celular',
        },
      };

      return contexts[props.context || 'createDocument'];
    });

    const buttonText = computed(() => {
      const locales = {
        initial: {
          firstButton: t('accountModule.limitExceddedInTrialModal.actions.openPlansLink'),
          secondButton:
            props.context === 'createDocument'
              ? t('accountModule.limitExceddedInTrialModal.actions.requestMoreDocuments')
              : t('accountModule.limitExceddedInTrialModal.actions.talkToSpecialist'),
        },
        form: {
          firstButton: t('general.action.cancel'),
          secondButton: t('general.action.send'),
        },
        success: {
          firstButton: t('general.action.close'),
        },
      };

      return locales[step.value];
    });

    const firstButtonStyle = computed(() => step.value === 'form' && { outlined: true });

    const secondButtonStyle = computed(() => {
      const secondButtonStyles = {
        initial: {
          outlined: true,
          disabled: false,
        },
        form: {
          disabled: !canSubmit.value,
          loading: props.loading,
        },
      };

      return secondButtonStyles[step.value];
    });

    const openPlansLink = () => props.account.trial && proxy.$goTo(props.plansLink);

    const handleSubmit = () => {
      emit('handle-limit-excedded-in-trial-form-submit', userData);
    };

    const handleClose = () => {
      emit('close', props.context);
      changeStep('initial');
    };

    const handleCloseOnFormStep = () => {
      if (currentModalStep.value === 'form') {
        emit('close', props.context);
      } else if (currentModalStep.value === 'initial') {
        changeStep('initial');
      }
    };

    const buttonActions = computed(() => {
      const stepsActions = {
        initial: {
          firstButton: openPlansLink,
          secondButton: () => changeStep('form'),
        },
        form: {
          firstButton: handleCloseOnFormStep,
          secondButton: handleSubmit,
        },
        success: {
          firstButton: handleClose,
          secondButton: {},
        },
      };

      return stepsActions[step.value];
    });

    return {
      step,
      userData,
      canSubmit,
      links,
      headerTitle,
      modalTitle,
      changeStep,
      openPlansLink,
      handleSubmit,
      handleClose,
      v$,
      headerClasses,
      modalClasses,
      actionsClasses,
      buttonText,
      buttonActions,
      firstButtonStyle,
      secondButtonStyle,
      modalMessage,
      modalImage,
    };
  },
});
</script>

<style lang="scss" module>
.modal {
  width: 792px;

  .header {
    width: 100%;
    margin-bottom: 0;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    color: var(--color-neutral-800);
  }

  .content {
    display: flex;
    justify-content: center;

    .image {
      margin: 0 auto;
      margin-bottom: var(--space-medium);
    }

    .emptyDocumentBalance {
      width: 100%;

      .titleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: var(--space-medium);
        gap: var(--space-small-xx);
        color: var(--color-brand-primary-400);
        background: var(--color-neutral-50);
        border-radius: var(--border-radius-medium);

        .mainHeading {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .title {
          margin: 0 0 0 var(--space-small-xx);
          font-size: var(--font-size-large-x);
          font-weight: var(--font-weight-semibold);
        }

        .subtitle {
          color: var(--color-neutral-1000);
        }
      }

      .messageContainer {
        margin-top: 28px;
      }
    }
  }

  .form {
    margin-top: 28px;

    > label {
      margin-bottom: 0;
    }

    .field {
      margin-bottom: var(--space-regular);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .actionsInitial {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: -8px;
    gap: var(--space-regular);

    .buttonsContainer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      gap: var(--space-small-xx);
    }
  }

  .actionsFormContainer {
    display: flex;
    align-items: center;
    width: 100%;

    .buttonsContainer {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      gap: var(--space-small-xx);
    }
  }

  .actionsSuccess {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .success {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .icon {
      margin-bottom: var(--space-regular);
    }

    .text {
      margin-top: var(--space-medium);
      font-size: var(--font-size-regular);
    }
  }

  .requiredLegend {
    align-self: center;
    flex: 1;
    color: var(--color-neutral-700);
  }

  @include breakpoint('large') {
    .content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }

  @include breakpoint('extra-small') {
    .content {
      .image {
        display: none;
      }

      .emptyDocumentBalance {
        .title {
          text-align: left;
        }
      }
    }

    .actionsInitial {
      .buttonsContainer {
        flex-direction: column;
      }
    }
  }

  @include breakpoint('large') {
    .actionsInitial {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: -46px;
      gap: 0;

      > button {
        width: unset;
      }
    }
  }
}
</style>
