export default {
  BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS: {
    category: 'block-modal-app',
    integrationFeatures: {
      moreInfo: {
        action: 'cross-sell-API-more-info',
      },
      upgrade: {
        action: 'cross-sell-API-request-feature',
      },
      close: {
        action: 'cross-sell-api-close',
      },
    },
    samlLogin: {
      moreInfo: {
        action: 'cross-sell-security-more-info',
      },
      upgrade: {
        action: 'cross-sell-security',
      },
      close: {
        action: 'cross-sell-security-close',
      },
    },
    customizationOfLogosAndColors: {
      moreInfo: {
        action: 'cross-sell-customization-more-info',
      },
      upgrade: {
        action: 'cross-sell-customization',
      },
      close: {
        action: 'cross-sell-customization-close',
      },
    },
    audit: {
      moreInfo: {
        action: 'cross-sell-audit-more-info',
      },
      upgrade: {
        action: 'cross-sell-audit',
      },
      close: {
        action: 'cross-sell-audit-close',
      },
    },
    additionalAuth: {
      moreInfo: {
        action: 'cross-sell-additional-auth-more-info',
      },
      upgrade: {
        action: 'cross-sell-additional-auth',
      },
      close: {
        action: 'cross-sell-additional-auth-close',
      },
    },
  },
  BLOCKED_FUNCTIONALITY_MODAL_LINKS: {
    default: {
      moreInfo: 'https://ajuda.clicksign.com/article/112-configuracoes-da-conta',
    },
    samlLogin: {
      moreInfo: 'https://ajuda.clicksign.com/article/112-configuracoes-da-conta',
    },
    customizationOfLogosAndColors: {
      moreInfo: 'https://ajuda.clicksign.com/article/273-personalizacao',
    },
    audit: {
      moreInfo: 'https://ajuda.clicksign.com/article/860-auditoria-da-conta',
    },
    additionalAuth: {
      moreInfo: 'https://ajuda.clicksign.com/article/615-como-utilizar-as-autenticacoes-adicionais',
    },
    integrationFeatures: {
      moreInfo: 'https://ajuda.clicksign.com/api',
    },
  },
};
