<template>
  <XCard
    v-if="visible"
    elevation="medium"
    :class="$style.dropdown"
  >
    <h5>{{ $t('layout.header.filter.title') }}</h5>
    <template v-if="!account.advancedSearchEnabled">
      <p>{{ $t('layout.header.filter.maintenance.title') }}</p>
      <span>{{ $t('layout.header.filter.maintenance.description') }}</span>
    </template>
    <template v-else>
      <XSelect
        ref="statusFilters"
        v-model="filters.node.status"
        :class="$style.fieldMargin"
        :items="statusFilters"
        :placeholder="$t('layout.header.filter.statusSelect.placeholder')"
        :label="$t('layout.header.filter.statusSelect.label')"
        multiple
        data-testid="searchStatusFilterSelect"
        full-width
        inner-dropdown
        @input="handleFilterChange"
      />
      <XTextInput
        v-model="filters.signer.documentation"
        v-mask="'###.###.###-##'"
        :validations="$v.filters.signer.documentation"
        :class="$style.fieldMargin"
        :label="$t('layout.header.filter.documentationInput')"
        placeholder="000.000.000-00"
        data-testid="searchDocumentationFilterInput"
        full-width
      />
      <HeaderFilterDateRangePicker
        v-model="filters.dates.createdAt"
        :class="$style.fieldMargin"
        :label="$t('layout.header.filter.createdAt')"
        :placeholder="$t('layout.header.filter.filterDateRange.placeholder')"
        :max-date="0"
        data-testid="searchCreatedAtFilterField"
        @clearDate="clearDateFilter('createdAt')"
      />
      <HeaderFilterDateRangePicker
        v-model="filters.dates.deadlineAt"
        :class="$style.fieldMargin"
        :label="$t('layout.header.filter.deadlineAt')"
        :placeholder="$t('layout.header.filter.filterDateRange.placeholder')"
        data-testid="searchDeadlineAtFilterField"
        @clearDate="clearDateFilter('deadlineAt')"
      />
      <HeaderFilterDateRangePicker
        v-model="filters.dates.finishedAt"
        :class="$style.fieldMargin"
        :label="$t('layout.header.filter.finishedAt')"
        :placeholder="$t('layout.header.filter.filterDateRange.placeholder')"
        :disabled="isOnlyStatusRunning"
        :max-date="0"
        :tooltip="isOnlyStatusRunning"
        data-testid="searchFinishedAtFilterField"
        @clearDate="clearDateFilter('finishedAt')"
      />
      <div :class="$style.bottomButtons">
        <XButton
          design="link"
          :class="$style.clearFiltersButton"
          @click="clearFilterFields"
        >
          {{ $t('layout.header.filter.buttons.clear') }}
        </XButton>
        <XButton
          :disabled="$v.$error"
          @click="$emit('search')"
        >
          {{ $t('layout.header.filter.buttons.apply') }}
        </XButton>
      </div>
    </template>
  </XCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, snakeCase, cloneDeep } from 'lodash';

import { sanitizeDateFilters } from '@base/utils';
import * as validators from '@base/validators';
import HeaderFilterDateRangePicker from './HeaderFilterDateRangePicker.vue';

const filtersState = () => ({
  node: {
    status: [],
  },
  signer: {
    documentation: undefined,
  },
  dates: {
    createdAt: undefined,
    deadlineAt: undefined,
    finishedAt: undefined,
  },
});

export default {
  name: 'HeaderSearchFilter',
  components: {
    HeaderFilterDateRangePicker,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: filtersState(),
      statusFilters: [
        {
          text: this.$t('layout.header.filter.status.running'),
          value: 'running',
        },
        {
          text: this.$t('layout.header.filter.status.closed'),
          value: 'closed',
        },
        {
          text: this.$t('layout.header.filter.status.canceled'),
          value: 'canceled',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      links: 'info/getLinks',
      account: 'info/getAccount',
    }),
    datesPayload() {
      return Object.keys(this.filters.dates).reduce((acum, dateKind) => {
        const date = this.filters.dates[dateKind];

        if (!date) return acum;

        return [
          ...acum,
          {
            startDate: date.start,
            endDate: date.end || date.start,
            dateKind: snakeCase(dateKind),
          },
        ];
      }, []);
    },
    filtersPayload() {
      const payload = {};
      const { node, signer } = this.filters;
      if (!isEmpty(node.status)) payload.node = node;
      if (!isEmpty(signer.documentation)) payload.signer = signer;
      if (!isEmpty(this.datesPayload)) payload.dates = this.datesPayload;
      return payload;
    },
    isOnlyStatusRunning() {
      const [first, others] = this.filters.node.status;
      return !others && first === 'running';
    },
  },
  watch: {
    filters: {
      handler: 'handleFilterChange',
      deep: true,
    },
    '$route.query': {
      handler: 'setFilledParamsToFilters',
      deep: true,
    },
    visible: {
      handler: 'handleOpenedFilters',
    },
  },
  created() {
    this.setFilledParamsToFilters();
  },
  methods: {
    handleFilterChange() {
      if (this.isOnlyStatusRunning) this.filters.dates.finishedAt = undefined;
      this.$emit('filters-payload', {
        filtersPayload: this.filtersPayload,
        hasError: this.$v.$error,
      });
    },
    clearFilterFields() {
      this.filters = filtersState();
    },
    setFilledParamsToFilters() {
      this.filters = filtersState();
      const { node, signer, dates } = cloneDeep(this.$route.query);
      if (!isEmpty(node)) this.filters.node = node;
      if (!isEmpty(signer)) this.filters.signer = signer;
      if (!isEmpty(dates)) {
        this.filters.dates = sanitizeDateFilters(dates);
      }
    },
    clearDateFilter(date) {
      this.filters.dates[date] = undefined;
    },
    finishedAtIsVisible() {
      const statuses = this.filters.node.status;
      if (statuses.length === 1 && statuses[0] === 'running') {
        this.filters.dates.finishedAt = undefined;
        return false;
      }
      return true;
    },
    handleOpenedFilters(value) {
      if (!value && this.$v.$error) this.filters.signer.documentation = undefined;
    },
  },
  validations: {
    filters: {
      signer: {
        documentation: {
          cpf: validators.cpf,
        },
      },
    },
  },
};
</script>

<style lang="scss" module>
.dropdown {
  position: absolute;
  z-index: var(--zindex-dropdown);
  top: 100%;
  right: 0;
  left: 0;
  max-width: 430px;
}

.fieldMargin {
  margin-bottom: 20px;
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;

  .clearFiltersButton {
    margin-right: 10px;
  }
}
</style>
